import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { CompareFunds } from 'common/components/templates/CompareFunds'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface CompareFundsPageProps {
    pageContext: any
}

export const CompareFundsPage: React.FC<CompareFundsPageProps> = ({ pageContext }) => {
    return <CompareFunds {...getCompareFundsPageData(pageContext)} />
}

export default CompareFundsPage

export const getCompareFundsPageData = (data: any) => ({
    jumbotron: getJumbotronData(data),
    layout: getLayoutData(data),
    information: {
        title: data.title_information,
        text: (data.paragraphs || []).map(
            (paragraph: { text: string }) => paragraph.text
        )
    },
    alerts: {
        alerts: (data.alerts__alerts || []).map((alert: any) => alert.text)
    },
})
