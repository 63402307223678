import axios from 'axios'
import { API_URL } from 'core/config'
import { useQuery } from 'react-query'

async function getFundComparisonData(fundIds: string) {
    const { data } = await axios.post(`${API_URL}/myonlineservices/FundApi/RetrieveFundComparisonChart?fundIds=${fundIds}`)
    return data
}

type Data = {
    FundId: string,
    Data: string
}[]

export function useFundComparisonQuery(fundIds: string) {
    return useQuery<Data, Error>(['fundsPerformance', fundIds], () => getFundComparisonData(fundIds))
}
