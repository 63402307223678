import { Box, Container, Stack } from '@chakra-ui/react'
import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { HtmlContent } from '@irishlife/ilgroupdesignsystem.atoms.html-content'
import { Spinner } from '@irishlife/ilgroupdesignsystem.atoms.spinner'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { ArrowLeftCircleIcon } from '@irishlife/ilgroupdesignsystem.icons'
import { Alert } from '@irishlife/ilgroupdesignsystem.molecules.alert'
import { navigate, useLocation } from '@reach/router'
import { AlertsProps } from 'common/components/organisms/Alerts'
import { FundsTable } from 'common/components/organisms/FundsTable'
import { InformationProps } from 'common/components/organisms/Information'
import { JumbotronProps as OldJumbotronProps } from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import { useFundCentreUrls } from 'common/hooks/useFundCentreUrls'
import { useFundComparisonQuery } from 'common/hooks/useFundComparisonQuery'
import { useFundsQuery } from 'common/hooks/useFundsQuery'
import React from 'react'
import TagManager from 'react-gtm-module'
import { useReactToPrint } from 'react-to-print'
import { CompareFundPerformanceChart } from './components/CompareFundPerformanceChart'

type CompareFundsProps = {
    layout: LayoutProps
    jumbotron: OldJumbotronProps
    information: InformationProps
    alerts: AlertsProps
}

export function CompareFunds(props: CompareFundsProps) {
    const { layout, jumbotron, information, alerts } = props
    const { search } = useLocation()
    const { fundListUrl } = useFundCentreUrls()

    const initialFundIds = React.useMemo(
        () => getInitialFundIds(search),
        [search]
    )

    const [fundIds, setFundIds] = React.useState<string[]>(initialFundIds)

    const { data = [], status: dataStatus } = useFundsQuery()
    const { data: performance = [], status: performanceStatus } =
        useFundComparisonQuery(initialFundIds?.join(','))

    const isError = performanceStatus === 'error' || dataStatus === 'error'
    const isLoading =
        performanceStatus === 'loading' || dataStatus === 'loading'

    const onGoBack = React.useCallback(
        () => navigate(fundListUrl),
        [fundListUrl]
    )

    React.useEffect(() => {
        if (initialFundIds.length === 0 || isError) onGoBack()
    }, [initialFundIds, isError, onGoBack])

    const initialFundNames = React.useMemo(() => {
        if (!performance || !data) return []
        const fundsFullData = data.filter((d) =>
            initialFundIds.some((id) => d.FundId === id)
        ) as Record<string, string>[]
        return fundsFullData.map(({ FundName }) => FundName)
    }, [data, performance, initialFundIds])

    const selectedFundNames = React.useMemo(() => {
        if (!performance || !data) return []
        const fundsFullData = data.filter((d) =>
            fundIds.some((id) => d.FundId === id)
        ) as Record<string, string>[]
        const orderedFundsFullData = fundsFullData.sort(
            (a, b) => fundIds.indexOf(a.FundId) - fundIds.indexOf(b.FundId)
        )
        return orderedFundsFullData.map(({ FundName }) => FundName)
    }, [data, performance, fundIds])

    const chartData = React.useMemo(() => {
        if (!performance || selectedFundNames.length === 0) return []
        return transformData(performance, selectedFundNames)
    }, [performance, selectedFundNames])

    const tableData: Record<string, string | number>[] = React.useMemo(() => {
        const ids = search.split('=')[1]?.split(',')
        return data.filter(({ FundId }) => ids.includes(FundId))
    }, [search, data])

    const onSelect = React.useCallback(
        (selectedIds: string[]) => {
            setFundIds(selectedIds)
        },
        [setFundIds]
    )

    const componentRef = React.useRef()

    const onPrint = useReactToPrint({
        content: () => componentRef.current as unknown as HTMLDivElement,
        onAfterPrint: () => {
            let chartContainer = document.getElementById(
                'chart-container'
            ) as HTMLDivElement
            ;(componentRef.current as unknown as HTMLDivElement).style.width =
                '100%'
            chartContainer.style.height = ''
        },
        onBeforeGetContent: async () => {
            let chartContainer = document.getElementById(
                'chart-container'
            ) as HTMLDivElement
            ;(componentRef.current as unknown as HTMLDivElement).style.width =
                '19cm'
            chartContainer.style.height = '400px'
            await sleep(50)
        },
    })

    if (isLoading) {
        return (
            <Layout {...layout}>
                <Stack
                    height='100vh'
                    pt={32}
                    isInline
                    width='full'
                    justifyContent='center'
                >
                    <Spinner size='lg' />
                </Stack>
            </Layout>
        )
    }

    return (
        <Layout {...layout} box={{ bgcolor: 'white' }}>
            <Container maxW='6xl'>
                <Stack py={[10, 16]}>
                    {jumbotron.subtitle && (
                        <Text variant={['title-sm', 'title-sm', 'title-md']}>
                            {jumbotron.subtitle}
                        </Text>
                    )}
                    <Text variant='title-2xl'>{jumbotron.title}</Text>
                    <HtmlContent sx={{ maxW: '3xl' }}>
                        {information.text}
                    </HtmlContent>
                </Stack>
                <Box>
                    <Button
                        onClick={onGoBack}
                        leftIcon={<ArrowLeftCircleIcon fontSize={26} />}
                    >
                        Back to Fund List
                    </Button>
                </Box>
            </Container>
            <Container maxW='6xl' px={0}>
                <Box
                    ref={
                        componentRef as unknown as React.MutableRefObject<HTMLDivElement>
                    }
                >
                    <Box pt={16} sx={{ '@media print': { padding: 0 } }}>
                        <FundsTable
                            data={tableData}
                            defaultSelected={initialFundIds}
                            onSelect={onSelect}
                        />
                    </Box>
                    <Box pt={10}>
                        <Text pl={2} pb={4} fontWeight='semibold' fontSize={18}>
                            Fund Price History
                        </Text>
                        <CompareFundPerformanceChart
                            data={chartData}
                            selectedFundNames={selectedFundNames}
                            initialFundNames={initialFundNames}
                            onPrint={onPrint}
                        />
                    </Box>
                </Box>
            </Container>
            <Container maxW='6xl' pt={10}>
                <Button
                    onClick={() => {
                        TagManager.dataLayer({
                            dataLayer: {
                                event: 'primaryButtonClick',
                                clickText: 'Contact us',
                                clickURL: '/contact-us',
                                clickID: jumbotron.callToAction?.analyticsId,
                            },
                        })
                        navigate('/contact-us')
                    }}
                >
                    Contact us
                </Button>
                <Stack maxW='xl' py={[10, 16]}>
                    {alerts.alerts.map((alert) => (
                        <Alert
                            key={alert}
                            status='warning'
                            textProps={{ fontWeight: 'bold' }}
                        >
                            {alert}
                        </Alert>
                    ))}
                </Stack>
            </Container>
        </Layout>
    )
}

function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
}

function getInitialFundIds(search: string) {
    return search.split('=')[1]?.split(',')
}

type Performance = { Data: string; FundId: string }[]

function transformData(
    performance: Performance = [],
    fundNames: string[] = []
) {
    if (performance.length === 0 || fundNames.length === 0) return []
    const parsed = performance.map(({ Data }) => JSON.parse(Data)) as [
        number,
        string
    ][][]
    const longestArrayIndex = getLongestArrayIndex(parsed)

    const timestamps = parsed[longestArrayIndex].map(
        ([timestamp, _]) => timestamp
    )

    const fundsWithTimeValueMap = parsed.reduce((acc, arr, index) => {
        const fundName = fundNames[index]

        const timeValueMap = arr.reduce((result, [timestamp, value]) => {
            result[timestamp] = value
            return result
        }, {} as Record<string, string>)

        acc.push({ fundName: fundName, timeValueMap })
        return acc
    }, [] as { fundName: string; timeValueMap: Record<string, string> }[])

    // transformedChartData
    return timestamps.reduce((acc, timestamp) => {
        const year = new Date(timestamp).getFullYear()
        let dataPoint: Record<string, number | boolean> = {
            year,
            timestamp,
            compare: true,
        }
        // create chart dataPoint object for each timestamp
        fundsWithTimeValueMap.forEach(({ fundName, timeValueMap }) => {
            const valueAtTimestamp = +timeValueMap[timestamp]
            let lastValue = 0
            if (!valueAtTimestamp) {
                // find latest value in current results if valueAtTimestamp is not found
                const list = acc.filter((item) => !!item[fundName])
                lastValue = (
                    list.length ? list[list.length - 1][fundName] : 0
                ) as number
            }
            const value = valueAtTimestamp || lastValue
            dataPoint[fundName] = value
        })
        acc.push(dataPoint)
        return acc
    }, [] as Record<string, number | boolean>[])
}

function getLongestArrayIndex(arr: [number, string][][]): number {
    return arr.reduce((acc, curr, index) => {
        if (curr.length > arr[acc].length) return index
        return acc
    }, 0)
}
