import React from 'react'
import { Text } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Box, Stack, HStack } from '@chakra-ui/react'
import { PerformanceChartHeader } from 'common/components/organisms/PerformanceChartHeader'
import {
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Brush,
    Line,
    LineChart,
} from 'recharts'
import { CustomTooltip } from 'common/components/organisms/CustomTooltip'

type CompareFundPerformanceChartProps = {
    data: Record<string, string | number | boolean>[]
    selectedFundNames: string[]
    initialFundNames: string[]
    onPrint: () => void
}

export function CompareFundPerformanceChart(
    props: CompareFundPerformanceChartProps
) {
    const {
        data = [],
        onPrint,
        selectedFundNames = [],
        initialFundNames = [],
    } = props
    const [historicalData, setHistoricalData] = React.useState([])
    const [fundToColorMap, setFundToColorMap] = React.useState<
        Record<string, string>
    >({})

    React.useEffect(() => {
        if (
            Object.keys(fundToColorMap).length > 0 ||
            data.length === 0 ||
            initialFundNames.length === 0
        ) {
            return
        }
        setFundToColorMap(createColorMap(initialFundNames))
    }, [fundToColorMap, initialFundNames, data])

    return (
        <Stack w='full' h='full' spacing={4}>
            <Box w='full' h='full'>
                <Box px={2}>
                    <PerformanceChartHeader
                        data={data as Record<string, number>[]}
                        onPrint={onPrint}
                        onChange={setHistoricalData}
                        showDateInputs
                    />
                </Box>
                <Box
                    id='chart-container'
                    height={['275px', '500px']}
                    width='full'
                    rounded='sm'
                    bg='gray.50'
                    mt={2}
                >
                    <ResponsiveContainer width='100%' height='100%'>
                        <LineChart
                            style={{ backgroundColor: '#F7FAFC' }}
                            data={historicalData}
                            margin={{
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: -40,
                            }}
                        >
                            <XAxis dataKey='year' />
                            <Tooltip
                                content={
                                    <CustomTooltip colorMap={fundToColorMap} />
                                }
                            />
                            <YAxis domain={['auto', 'auto']} hide />
                            {selectedFundNames.map((fundName: string) => {
                                const color = fundToColorMap[fundName]
                                return (
                                    <Line
                                        isAnimationActive={false}
                                        key={fundName}
                                        type='monotone'
                                        dataKey={fundName}
                                        stroke={color}
                                        dot={false}
                                    />
                                )
                            })}
                            <Brush
                                height={0}
                                width={0}
                                dataKey='year'
                                stroke='#5162AC'
                            />
                        </LineChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
            <Stack spacing={0} px={2}>
                {Object.entries(fundToColorMap).map(([fundName, color]) => (
                    <HStack key={fundName}>
                        <Box boxSize={2} rounded='full' bg={color} />
                        <Text variant='body-sm'>{fundName}</Text>
                    </HStack>
                ))}
            </Stack>
        </Stack>
    )
}

function createColorMap(items: string[]) {
    return items.reduce((acc, key) => {
        acc[key] = getRandomColor()
        return acc
    }, {} as Record<string, string>)
}

function getRandomColor() {
    return (
        '#' +
        Math.floor(Math.random() * (0xffffff + 1))
            .toString(16)
            .padStart(6, '0')
    ) // in case the number is too small to fill 6 hex digits
}
